<template>
  <v-container fluid class="signin-page fill-height">

      <v-col cols="12" class="text-center dssda">
        <div>
          <strong class="str4f">ADMIN</strong>
        </div>
      </v-col>

    <v-row
      justify="center"
      class="fill-height"
    >

      <v-col
        cols="12"
        sm="6"
      >
        <v-card>
          <v-toolbar
            dense
            dark
            color="#f3976a"
          >
            <v-toolbar-title>Iniciar sesión</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-form
            v-model="valid"
            @submit.prevent="handleLogin"
            class="forv56"
          >
            <v-card-text>
              <v-row>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    v-model="request.email"
                    dense
                    :rules="[
                      value => !!value || 'El correo electrónico es obligatorio.',
                      value => value.length > 3 || 'El correo electrónico debe ser mayor a 3 caracteres.'
                    ]"
                    outlined
                    label="Correo Electrónico"
                    name="email"
                    prepend-inner-icon="mdi-mail"
                    type="text"
                    color="#f3976a"
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="6"
                >
                  <v-text-field
                    id="password"
                    v-model="request.password"
                    dense
                    :rules="[
                      value => !!value || 'La contraseña es obligatoria.',
                      value => value.length >= 8 || 'La contraseña debe ser mayor o igual a 8 caracteres.'
                    ]"
                    outlined
                    label="Contraseña"
                    name="password"
                    prepend-inner-icon="mdi-textbox"
                    type="password"
                    color="#f3976a"
                  />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                class="ml-4"
                outlined
                type="submit"
                :loading="loading"
                :disabled="!valid"
                color="#f3976a"
              >
                Iniciar sesión
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  export default {
    name: 'SigninView',

    data () {
      return {
        valid: false,
        request: {
          email: '',
          password: '',
        },
        error: null,
        loading: false,
      }
    },
    async created () {
      localStorage.clear()
      // await this.$store.dispatch('user/signout')
    },
    methods: {
      async handleLogin () {
        this.loading = true
        try {
          const response = await this.$store.dispatch('user/signin', this.request)

          if (response.message) {
            throw new Error({
              timeout: 3000,
              show: true,
              icon: null,
              message: 'Correo o Contraseña Invalidos!'
            })
          }

          await this.$router.replace({ path: '/es/admin' })
          this.loading = false
        } catch (e) {
          this.$root.setSystemMessage(e)
          this.loading = false
        }
      },
    },
  }
</script>

<style>
.signin-page {
  background: #fad3ba;
  /* border-bottom: 4px solid white; */
}

.primary-color {
  background: #f3976a;
}

.str4f {
  font-size: 45px;
  font-family: "Montserrat",Sans-serif;
  font-weight: 400;
  letter-spacing: 3px;
  color: #f3976a ;
}

.dssda {
  margin-top: 50px;
  margin-bottom: 60px;
}

.forv56 {
  padding: 10px;
}

</style>
